<template>

<section class="section">
    <div class="container">

        <div class="has-text-centered mb-6">
            <p class="title is-3">My Links</p>
            <p class="subtitle is-5">Check out my projects, professional portfolio, socials or the kind of music I listen to.</p>
        </div>

        <nav class="level is-mobile">
            <div class="level-item has-text-centered">
                <a class="link github" href="https://github.com/Aldin-SXR"><span class="icon is-large"><i class="fab fa-3x fa-github"></i></span></a>
            </div>
            <div class="level-item has-text-centered">
                <a class="link linkedin" href="https://www.linkedin.com/in/aldin-kovacevic/"><span class="icon is-large"><i class="fab fa-3x fa-linkedin"></i></span></a>
            </div>
            <div class="level-item has-text-centered">
                <a class="link fb" href="https://www.facebook.com/aldin.sxr"><span class="icon is-large"><i class="fab fa-3x fa-facebook"></i></span></a>
            </div>
            <div class="level-item has-text-centered">
                <a class="link ig" href="https://www.instagram.com/aldin_sxr/"><span class="icon is-large"><i class="fab fa-3x fa-instagram"></i></span></a>
            </div>
            <div class="level-item has-text-centered">
                <a class="link spotify" href="https://open.spotify.com/user/bjruw0dswnw3ryrh8zwqxzq3d?si=BpBsr_4cRo6IgbtxtlRnvQ"><span class="icon is-large"><i class="fab fa-3x fa-spotify"></i></span></a>
            </div>
        </nav>
    </div>
</section>

</template>

<script>
export default {
    name: 'Socials'
}
</script>

<style>

</style>